<template>
    <div>
        <div class="form-row w-1/2">
            <div class="form-col flex">
                <div class="mr-4">
                    <label>Icon</label>
                    <icon-picker v-model="selectedIcon" :icons="icons"></icon-picker>
                    <span v-if="hasError('selectedIcon')" class="form-error">{{ showError('selectedIcon') }}</span>
                </div>
                <div>
                    <label for="amenityName">Name</label>
                    <text-input id="amenityName" v-model="name" />
                    <span v-if="hasError('name')" class="form-error">{{ showError('name') }}</span>
                </div>
            </div>
        </div>
        <modal-footer :primary="save"></modal-footer>
    </div>
</template>

<script>
import Auth from "@/mixins/Auth";
import {mapActions, mapGetters} from "vuex";
import NotifyMixin from "@/mixins/NotifyMixin";
import IconPicker from "@/components/ui/IconPicker";
import ModalNavigation from '@/mixins/ModalNavigation';
import ModalFooter from "@/components/ui/modals/ModalFooter";
import TextInput from '@/components/ui/TextInput';

export default {
    mixins: [ ModalNavigation, Auth, NotifyMixin, TextInput ],
    components: { IconPicker, ModalFooter, TextInput },
    data: () => {
        return {
            website: null,
            isProcessing: false,
            amenity: null,
            name: null,
            selectedIcon: null,
        }
    },
    computed: {
        ...mapGetters({
            getCustomerAmenityByName: 'amenities/getCustomerAmenityByName',
            icons: 'amenities/getIcons',
            getWebsiteInfo: 'website_info/getWebsiteInfo'
        }),
    },
    methods: {
        ...mapActions({
            notifySuccessBack: 'alerts/notifySuccessBack',
            notifyError: 'alerts/notifyError',
        }),
        save() {
            if(this.validator.passes()) {
                let data = {
                    customers_id: this.customerId,
                    amenityName: this.amenity.community_amenities_name,
                    customer_community_amenities_name: this.name,
                    amenity_icons_name: this.selectedIcon
                };

                this.isProcessing = true;

                let params = {
                    communityId: this.website.communities_id,
                    data: data
                };

                this.$cmsDataProvider.create('updateAmenity', params).then( () => {
                    this.isProcessing = false;
                    this.notifySuccess('The amenity was updated successfully');
                    this.redirect('websites.amenities', { id: this.website.id });
                })
            }
        },
        async getAmenityInfo() {
            await this.$cmsDataProvider.get('amenitybyName', {amenityName: this.$route.params.amenityId ? this.$route.params.amenityId : this.$route.params.amenityName})
            .then((response) => {
                if(!response.message) {
                    this.amenity = response
                }else{
                    this.notifyError('Amenity not found');
                }
            }).catch(() => {
                this.notifyError('There was an error getting the Amenity ');
                this.website = null
            });
        }
    },
    validations: {
        'name' : 'required',
        'selectedIcon': 'required'
    },
    created() {
        this.initValidator();
    },
    async mounted() {
        this.website = this.getWebsiteInfo
        await this.getAmenityInfo()
        this.name = this.amenity.community_amenities_name;
        this.selectedIcon = this.amenity.amenity_icons_name;
        this.setActiveModalTitle(this.name);
    },

}
</script>
